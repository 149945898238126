import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import api from '@/services/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    admin: null,
    isLogin: false,
    cart: [],
    products: [],
    brands: []
  },
  mutations: {
    saveUser(state, { user, isLogin }) {
      state.admin = user
      state.isLogin = isLogin
    },
    removeAdmin(state) {
      state.admin = null
      state.isLogin = false
    },
    addToCart(state, data) {
      state.cart.push(data)
    },
    removeFromCart(state, data) {
      let index = state.cart.findIndex(prod => prod.index === data.index)
      state.cart.splice(index, 1)
    },
    addProducts(state, { products, brands }) {
      state.products = products
      state.brands = brands
    }
  },
  actions: {
    async loadProducts({ commit }) {
      let products = await api.getCSV()
      let brands = products
        .map(value => ({ brand: value.brand.toUpperCase(), category: value.category.toUpperCase(), subcategory: value.subcategory.toUpperCase() }))
        .filter((value, index, self) => index === self.findIndex(element => element.brand === value.brand && element.category === value.category && element.subcategory === value.subcategory))
        .sort((a, b) => (a.brand < b.brand ? -1 : 1))

      commit('addProducts', { products, brands })
    },
    async login({ commit }, { user, password }) {
      try {
        if (await api.login({ user, password })) {
          commit('saveUser', { user, isLogin: true })
          return true
        } else {
          return false
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async saveFiles({}, { csv, images }) {
      let count = 0

      if (!!csv) {
        let response = await api.loadCSV({ csv })
        count += 1
      }

      if (!!images) {
        console.log('🚀 ~ file: index.js ~ line 68 ~ saveFiles ~ images', images)
        let response = await api.loadIMG(images)
        count += 2
      }

      return count
    },
    async sendMail({}, { name, last_name, mail, phone, comments, products, branch }) {
      let response = await api.sendMail(name, last_name, mail, phone, comments, products, branch)
      return response
    },
    addToCart({ state, commit }, data) {
      if (!state.cart.find(value => value.index === data.index)) {
        commit('addToCart', data)
      }
    },
    removeFromCart({ commit }, data) {
      commit('removeFromCart', data)
    },
    removeAdmin({ commit }) {
      commit('removeAdmin')
    }
  },
  modules: {},
  plugins: [new VuexPersistence().plugin]
})
