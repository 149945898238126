import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'
import VueRecaptcha from 'vue-recaptcha';
import '@/assets/theme.scss'

Vue.config.productionTip = false

localize('es', es)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('vue-recaptcha', VueRecaptcha)

import FeatherWrapper from 'vue-feather-icons-wrapper'
Vue.use(FeatherWrapper)

Vue.prototype.$alert = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
