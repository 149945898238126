import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/marcas',
    name: 'Brands',
    component: () => import('@/views/Brands.vue')
  },
  {
    path: '/novedades',
    name: 'Novelties',
    component: () => import('@/views/Novelties.vue')
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/aviso-de-privacidad',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue')
  },
  {
    path: '/productos',
    component: () => import('@/views/Products.vue'),
    children: [
      {
        path: '',
        name: 'Catalog',
        component: () => import('@/views/Catalog.vue')
      },
      {
        path: ':product_id',
        name: 'Product',
        component: () => import('@/views/Product.vue')
      }
    ]
  },
  {
    path: '/dashboard',
    component: () => import('@/views/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: 'admin',
        name: 'Admin',
        meta: { requieresAuth: true },
        component: () => import('@/views/Admin.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requieresAuth)) {
    if (store.state.isLogin) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router
