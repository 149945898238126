import axios from 'axios'

const client = axios.create({
  baseURL: `${process.env.VUE_APP_URL_SERVER}/services`
})

export default class API {
  static async login({ user, password }) {
    let { data } = await client.post('/login.php', { user, password }, { headers: { 'Content-Type': 'application/json' } })
    return data
  }

  static async loadCSV({ csv }) {
    let { data } = await client.post('/loadCSV.php', { csv }, { headers: { 'Content-Type': 'application/json' } })
    return data
  }

  static async loadIMG(formData) {
    let { data } = await client.post('/loadIMG.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })

    console.log(data)
    return data
  }

  static async getCSV() {
    let { data } = await client.post('/getCSV.php', null, { headers: { 'Content-Type': 'application/json' } })
    return data
  }

  static async sendMail(name, last_name, mail, phone, comments, listProducts, branch) {
    try {
      let title = 'Solicitud de cotización'
      listProducts = listProducts ? listProducts : []

      if (branch === 'tlax') {
        let mailBranch = process.env.VUE_APP_MAIL_TLX
        let products = listProducts
          .filter(value => value.state)
          .map(value => '* ' + value.name)
          .join('\n')

        let message = `Nombre: ${name} ${last_name}\n\nCorreo: ${mail}\n\nTeléfono: ${phone}\n\nComentarios: ${comments}\n\nProductos:\n${products}`

        console.log('tlx', message)

        let { data } = await client.post('/sendMail.php', { mail: mailBranch, title, message }, { headers: { 'Content-Type': 'application/json' } })

        if (!data) {
          throw error
        }
      } else {
        let tlx = listProducts
          .filter(value => value.tlx === 1 && value.state)
          .map(value => '* ' + value.name)
          .join('\n')

        let products = listProducts
          .filter(value => value.tlx === 0 && value.state)
          .map(value => '* ' + value.name)
          .join('\n')

        if (tlx.length !== 0) {
          let mailBranch = process.env.VUE_APP_MAIL_TLX
          let message = `Nombre: ${name} ${last_name}\n\nCorreo: ${mail}\n\nTeléfono: ${phone}\n\nComentarios: ${comments}\n\nProductos:\n${tlx}`

          console.log('tlx', message)

          let { data } = await client.post('/sendMail.php', { mail: mailBranch, title, message }, { headers: { 'Content-Type': 'application/json' } })

          if (!data) {
            throw error
          }
        }

        if (products.length !== 0) {
          let message = `Nombre: ${name} ${last_name}\n\nCorreo: ${mail}\n\nTeléfono: ${phone}\n\nComentarios: ${comments}\n\nProductos:\n${products}`

          if (branch === 'cdmx') {
            let mailBranch = process.env.VUE_APP_MAIL_CDMX

            console.log('cdmx', message)

            let { data } = await client.post('/sendMail.php', { mail: mailBranch, title, message }, { headers: { 'Content-Type': 'application/json' } })

            if (!data) {
              throw error
            }
          } else {
            let mailBranch = process.env.VUE_APP_MAIL_XILO

            console.log('xilo', message)

            let { data } = await client.post('/sendMail.php', { mail: mailBranch, title, message }, { headers: { 'Content-Type': 'application/json' } })
            if (!data) {
              throw error
            }
          }
        }
      }

      return true
    } catch (error) {
      return false
    }
  }
}
