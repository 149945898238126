<template lang="pug">
#app
  router-view
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['loadProducts']),
  },
  mounted() {
    this.loadProducts()
  },
}
</script>

<style lang="scss">
body,
html {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}
</style>
